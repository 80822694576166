import { CreditCardTypes } from './const/CreditCardTypes';

export class CreditCard {
  readonly lastDigits: string | null;
  readonly cardExpiration: string | null;
  readonly cardToken: string | null;
  readonly type: string | null;

  constructor(response: any) {
    this.lastDigits = (response && response.lastFour) || null;
    this.cardToken = (response && response.paypageRegistrationId) || null;
    this.cardExpiration =
      (response && response.expMonth + response.expYear) || null;
    const creditCardType = CreditCardTypes.find(
      val => val.short === response.type
    );

    this.type =
      (response && response.type && creditCardType && creditCardType.id) ||
      null;
  }
}
