import React, { PropsWithChildren, useRef } from 'react';
import MaterialUiModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => {};
  children: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.typography.pxToRem(20),
      '&:focus': {
        outline: 'none'
      }
    },
    paperContent: {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '1rem 1rem'
    },
    BackdropPropsRoot: {
      backgroundColor: 'rgba(240, 240, 240, 0.8)',
      backdropFilter: 'blur(2px)'
    }
  })
);

export const modalTestId = 'ModalComponent';

export const Modal = (props: PropsWithChildren<ModalProps>) => {
  const { isOpen, children } = props;
  const rootRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  return (
    <div data-testid={modalTestId} ref={rootRef}>
      <MaterialUiModal
        className={classes.modal}
        open={isOpen}
        BackdropComponent={Backdrop}
        BackdropProps={{ classes: { root: classes.BackdropPropsRoot } }}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <div className={classes.paperContent}>{children}</div>
        </div>
      </MaterialUiModal>
    </div>
  );
};
