import React, { FC, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { PaymentLogRequester } from './request-form/PaymentLogRequester';
import { PaymentLogSubmitter } from './PaymentLogSubmitter';
import { PaymentLog } from '../../../domain/PaymentLog/PaymentLog';
import { Footer } from '../../layout/footer/Footer';

import styles from './SavePayment.module.scss';

export const savePaymentTestId = 'SavePaymentComponent';

export const SavePayment: FC = () => {
  const [paymentLogRequest, setPaymentLogRequest] = useState<
    PaymentLog | undefined
  >(undefined);

  return (
    <div className={styles.savePayment}>
      <Box p="2rem" data-testid={savePaymentTestId}>
        <Grid container spacing={0}>
          <Grid item xs={12} lg={10}>
            <PaymentLogRequester onPaymentLogRequest={setPaymentLogRequest} />

            {paymentLogRequest && (
              <PaymentLogSubmitter paymentLog={paymentLogRequest} />
            )}
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
};
