import React, { useState } from 'react';

import { Result } from '../../../../utils/Result';
import {
  PaymentLogRequestForm,
  PaymentLogRequestFormValues
} from './PaymentLogRequestForm';
import { PaymentLog } from '../../../../domain/PaymentLog/PaymentLog';
import { APIError } from '../../../../domain/APIError';
import { getPaymentLogByPaymentLogIdResult } from './getPaymentLogByPaymentLogIdResult';
import { PaymentStatuses } from '../../../../domain/PaymentLog/enums/PaymentStatuses';

export const paymentLogRequesterTestId = 'PaymentLogRequesterComponent';

interface Props {
  onPaymentLogRequest: (requestLog: PaymentLog) => void;
}

export const PaymentLogRequester = ({ onPaymentLogRequest }: Props) => {
  const [isRequestingLog, requestingLog] = useState(false);
  const [paymentLogRequest, setPaymentLogRequest] = useState<
    PaymentLog | undefined
  >(undefined);
  const [apiError, setApiError] = useState<APIError | undefined>(undefined);

  const isPaymentStatusValid = (paymentLog: PaymentLog) => {
    return (
      paymentLog.paymentStatus !== PaymentStatuses.APPROVED &&
      paymentLog.paymentStatus !== PaymentStatuses.CANCELLED_BY_SIMS
    );
  };

  const handleSubmit = async ({
    paymentLogId
  }: PaymentLogRequestFormValues) => {
    requestingLog(true);

    const result: Result<
      PaymentLog,
      APIError
    > = await getPaymentLogByPaymentLogIdResult(paymentLogId);

    requestingLog(false);

    if (result.isOk) {
      if (result.value) {
        if (result.value.storeMerchantId === null) {
          setApiError({
            errors: [
              {
                code: '500',
                message:
                  'No Merchant ID provided, please contact the Solution Center'
              }
            ]
          });
        } else if (isPaymentStatusValid(result.value)) {
          setPaymentLogRequest(result.value);

          onPaymentLogRequest(result.value);
        } else {
          setApiError({
            errors: [
              {
                code: '400',
                message: result.value.statusMessage
              }
            ]
          });
        }
      }
    } else {
      setApiError(result.error);
    }
  };

  return (
    <div data-testid={paymentLogRequesterTestId}>
      <PaymentLogRequestForm
        isRequesting={isRequestingLog}
        isDisabled={!!paymentLogRequest}
        apiError={apiError}
        setApiError={setApiError}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
