import React, { useCallback } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import validate from 'uuid-validate';
import { get } from 'lodash';
import { Alert } from '@material-ui/lab';

import { APIErr, APIError } from '../../../../domain/APIError';
import { InputField } from '../../../common/form/input-field/InputField';
import { Button } from '../../../common/Button';

import styles from './PaymentLogRequestForm.module.scss';

export const paymentRequestIdFormTestId = 'PaymentLogRequestFormComponent';

export interface PaymentLogRequestFormValues {
  paymentLogId: string;
}

interface Props {
  isRequesting: boolean;
  isDisabled: boolean;
  apiError?: APIError;
  setApiError: (values: APIError | undefined) => void;
  onSubmit: (values: PaymentLogRequestFormValues) => void;
}

export const paymentLogRequestFormSubmitButtonTestId =
  'paymentLogRequestFormSubmitButton';

export const paymentLogIdName = 'paymentLogId';

export const PaymentLogRequestForm = (props: Props) => {
  const { isRequesting, isDisabled, apiError, onSubmit, setApiError } = props;

  const { register, handleSubmit, errors, clearError } = useForm<
    PaymentLogRequestFormValues
  >();

  const isInputError = (apiError: APIError | undefined) => {
    return get(apiError, 'errors.[0].code') === '400';
  };

  const handleFormSubmit = (values: PaymentLogRequestFormValues) => {
    onSubmit(values);
    setApiError(undefined);
  };

  const handleChange = useCallback(() => {
    setApiError(undefined);
    clearError();
  }, [setApiError, clearError]);

  return (
    <>
      <form
        data-testid={paymentRequestIdFormTestId}
        className={styles.form}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <label className={styles.label}>
              <span>Payment Reference ID</span>
            </label>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <InputField
                  name="paymentLogId"
                  refContent={register({
                    required: {
                      value: true,
                      message: 'Payment reference ID is required'
                    },
                    validate: value => {
                      return validate(value) || value.match(/^\w{6}$/) !== null;
                    }
                  })}
                  onChange={handleChange}
                  placeholder="Enter Payment Reference ID"
                  autoComplete="off"
                  hasError={!!(errors.paymentLogId || isInputError(apiError))}
                  disabled={isRequesting || isDisabled}
                />
              </Box>
              {isRequesting ? (
                <div className={styles.progress}>
                  <Box ml="1.5rem">
                    <CircularProgress size={30} />
                  </Box>
                </div>
              ) : (
                <></>
              )}
              {!isRequesting && !isDisabled ? (
                <Box ml="1.5rem">
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    type="submit"
                    data-testid={paymentLogRequestFormSubmitButtonTestId}
                    disabled={isRequesting || !!errors.paymentLogId}
                  >
                    Validate
                  </Button>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <div>
              {isDisabled ? (
                <label>
                  <Typography
                    component="span"
                    variant="body2"
                    color="secondary"
                  >
                    Valid Payment Reference ID
                  </Typography>
                </label>
              ) : (
                <></>
              )}
              {apiError && isInputError(apiError) && !errors.paymentLogId ? (
                <label>
                  <Typography component="span" variant="body2" color="error">
                    {apiError.errors.map((e: APIErr) => (
                      <span key={e.code}>{e.message}</span>
                    ))}
                  </Typography>
                </label>
              ) : (
                <></>
              )}

              <ErrorMessage
                errors={errors}
                name="paymentLogId"
                message="The Payment Reference ID&#39;s format is invalid"
              >
                {({ message }) =>
                  message && (
                    <label>
                      <Typography
                        component="span"
                        variant="body2"
                        color="error"
                      >
                        {message}
                      </Typography>
                    </label>
                  )
                }
              </ErrorMessage>
            </div>
          </Grid>
        </Grid>
      </form>

      {apiError && !isInputError(apiError) && (
        <Alert severity="error">
          {apiError.errors.map((e: APIErr) => (
            <span key={e.code}>{e.message}</span>
          ))}
        </Alert>
      )}
    </>
  );
};
