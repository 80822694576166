import { AxiosError } from 'axios';

import { result, Result } from '../../../../utils/Result';
import { getPaymentLogByPaymentLogId } from '../../../../api/payment-log-client';
import { APIError } from '../../../../domain/APIError';
import { PaymentLog } from '../../../../domain/PaymentLog/PaymentLog';

export const getPaymentLogByPaymentLogIdResult = async (
  paymentLogId: string
): Promise<Result<PaymentLog, APIError>> => {
  let resp: PaymentLog;
  let error: APIError;

  await getPaymentLogByPaymentLogId(paymentLogId)
    .then((res: PaymentLog) => {
      resp = res;
    })
    .catch((err: AxiosError) => {
      error = err && err.response ? err.response.data : {};
    });

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (error) {
    return result.error(error);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return result.ok(resp);
};
