import { useEffect } from 'react';

export const useBeforeUnload = (
  value: ((event: BeforeUnloadEvent | PopStateEvent) => any) | boolean
) => {
  useEffect(() => {
    if (value === false) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      let returnValue;

      if (typeof value === 'function') {
        returnValue = value(event);
      } else {
        returnValue = value;
      }

      if (returnValue) {
        event.preventDefault();
        event.returnValue = returnValue;
      }

      return returnValue;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [value]);
};
