export enum PaymentStatuses {
  INITIATED = 'Initiated',
  APPROVED = 'Approved',
  CANCELLED_BY_SIMS = 'CancelledBySIMS',
  DECLINED = 'Declined',
  RETRY = 'Retry',
  PREPAID_CARD_IS_NOT_RELOADABLE = 'Prepaid Card is not Reloadable',
  DUPLICATE_PAYMENT = 'Duplicate Payment',
  ESS_FAILURE = 'ESS Failure',
  ESB_FAILURE = 'ESB Failure'
}
