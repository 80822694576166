import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { PaymentRoute } from './PaymentRoute';
import { SavePayment } from '../../screens/save-payment/SavePayment';

export const routesTestId = 'RoutesComponent';

export const Routes = () => (
  <div data-testid={routesTestId}>
    <Switch>
      <Route exact path={PaymentRoute.Index}>
        <Redirect to={PaymentRoute.Save} />
      </Route>
      <Route exact path={PaymentRoute.Save} component={SavePayment} />
      <Route>
        <Redirect to={PaymentRoute.Index} />
      </Route>
    </Switch>
  </div>
);
