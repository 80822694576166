import React from 'react';
import { Prompt } from 'react-router-dom';

import { PromptProps } from './LooseWorkPrompt';

export const RouterPrompt = ({ when, message }: PromptProps) => {
  const promptMessage =
    message || 'Leave page? Changes that you made may not be saved.';

  return <Prompt when={when} message={promptMessage} />;
};
