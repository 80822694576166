import React, { useCallback, useMemo } from 'react';

import { useAuthContext } from '../../../context/auth/AuthContext';
import { appConfig } from '../../../config/appConfig';

import styles from './Unauthenticated.module.scss';

export const unauthenticatedTestId = 'unauthenticatedTestId';

export const Unauthenticated = () => {
  const { login, error } = useAuthContext();

  const handleClick = useCallback(() => {
    if (login) {
      login();
    }
  }, [login]);

  return useMemo(
    () => (
      <div className={styles.centered} data-testid={unauthenticatedTestId}>
        <h1>{appConfig.appName}</h1>
        <div className={styles.note}>
          {!error ? (
            <span>Use your Rent-A-Center credentials to sign in</span>
          ) : (
            <span>{`The following error occured: ${error}`}</span>
          )}
        </div>
        <button
          className={styles.signin}
          onClick={handleClick}
          data-testid="signin"
        >
          Sign in with Single Sign-On
        </button>
      </div>
    ),
    [error, handleClick]
  );
};
