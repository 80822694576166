import { AxiosError } from 'axios';

import { result, Result } from '../../../utils/Result';
import { processPaymentLog } from '../../../api/payment-log-client';
import { APIError } from '../../../domain/APIError';
import { ProcessPaymentLogRequest } from '../../../domain/PaymentLog/ProcessPaymentLogRequest';
import { ProcessPaymentLogResponse } from '../../../domain/PaymentLog/ProcessPaymentLogResponse';

export const processPaymentLogResult = async (
  paymentLogSubmitData: ProcessPaymentLogRequest
): Promise<Result<ProcessPaymentLogResponse, APIError>> => {
  let resp: ProcessPaymentLogResponse;
  let error: APIError;

  await processPaymentLog(paymentLogSubmitData)
    .then((res: ProcessPaymentLogResponse) => {
      resp = res;
    })
    .catch((err: AxiosError) => {
      error = err && err.response ? err.response.data : {};
    });

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (error) {
    return result.error(error);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return result.ok(resp);
};
