import React from 'react';
import clsx from 'clsx';

import styles from './InputField.module.scss';

export const inputControlTestId = 'InputFieldComponent';

interface InputFieldProps {
  label?: string;
  name: string;
  placeholder?: string;
  id?: string;
  inputSize?: string;
  disabled?: boolean;
  ariaLabelledby?: string;
  hasError?: boolean;
  isLowVisible?: boolean;
  isInvalid?: boolean;
  refContent?: (ref: HTMLInputElement | null) => void;
}

type Props = InputFieldProps & JSX.IntrinsicElements['input'];

export const InputField = (props: Props) => {
  const {
    label,
    name,
    placeholder,
    id,
    inputSize = 'medium',
    disabled,
    ariaLabelledby,
    hasError,
    isLowVisible,
    isInvalid,
    refContent,
    autoComplete = 'off',
    ...rest
  } = props;

  return (
    <div
      className={clsx({
        [styles.opacity02]: isLowVisible
      })}
      data-testid={inputControlTestId}
    >
      {label && (
        <label htmlFor={id || name} className={styles.inputControlLabel}>
          {label}
        </label>
      )}
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        id={id || name}
        className={clsx({
          [styles.inputControlItem]: true,
          [styles.error]: hasError,
          [styles.invalid]: isInvalid,
          [styles.disabled]: disabled,
          [styles.small]: inputSize === 'small'
        })}
        disabled={disabled}
        aria-labelledby={ariaLabelledby}
        ref={refContent}
        autoComplete={autoComplete}
        {...rest}
      />
    </div>
  );
};
