export const CreditCardTypes = [
  {
    id: '1',
    value: 'MasterCard',
    short: 'MC'
  },
  {
    id: '2',
    value: 'Visa',
    short: 'VI'
  },
  {
    id: '3',
    value: 'American Express',
    short: 'AX'
  },
  {
    id: '4',
    value: 'Discover',
    short: 'DI'
  },
  {
    id: '5',
    value: "Diner's Club",
    short: 'CD'
  },
  {
    id: '6',
    value: 'JCB',
    short: 'JC'
  },
  {
    id: '7',
    value: 'PayPal',
    short: 'PP'
  },
  {
    id: '8',
    value: 'PayPal Credit',
    short: 'BL'
  },
  {
    id: '9',
    value: 'eCheck',
    short: 'EC'
  },
  {
    id: '10',
    value: 'Gift Card',
    short: 'GC'
  },
  {
    id: '11',
    value: 'Unknown',
    short: ''
  }
];
