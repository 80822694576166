import { client } from './client';
import { ProcessPaymentLogRequest } from '../domain/PaymentLog/ProcessPaymentLogRequest';
import { ProcessPaymentLogResponse } from '../domain/PaymentLog/ProcessPaymentLogResponse';
import { PaymentLog } from '../domain/PaymentLog/PaymentLog';

function getPaymentLogByPaymentLogId<T = string, R = PaymentLog>(
  paymentLogId: T
): Promise<R> {
  return client(`altpay/payment-log/${paymentLogId}`, {
    method: 'GET'
  });
}

function processPaymentLog<
  T = ProcessPaymentLogRequest,
  R = ProcessPaymentLogResponse
>(paymentLog: T): Promise<R> {
  return client('altpay/payment-log/process', {
    method: 'POST',
    body: paymentLog
  });
}

export { getPaymentLogByPaymentLogId, processPaymentLog };
