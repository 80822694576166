import { appConfig } from '../../../../config/appConfig';
import { VantivConfig } from '../../../../domain/PaymentLog/VantivConfig';

declare let LitlePayframeClient: any;

export const config: VantivConfig = {
  paypageId: appConfig.vantiv.PAY_PAGE_ID,
  merchantId: '',
  style: 'iframeenracpad',
  reportGroup: 'SIMS',
  div: '',
  timeout: 15000,
  height: '250',
  showCvv: true,
  numYears: '12',
  placeholderText: {
    cvv: 'CVV',
    accountNumber: 'Credit Card Number'
  },
  tabIndex: {
    cvv: 3,
    accountNumber: 2,
    expMonth: 3,
    expYear: 4
  },
  enhancedUxFeatures: {
    inlineFieldValidations: true
  },
  callback: 0
};

export const getConfig = (
  storeMerchantId: string | null,
  containerId: string,
  callback: any
) => {
  const localConfig = config;

  localConfig.merchantId = storeMerchantId;
  localConfig.div = containerId;
  localConfig.callback = callback;

  return localConfig;
};

export const loadVantivIFrame = (
  storeMerchantId: string | null,
  containerId: string,
  callback: any
) => {
  return new LitlePayframeClient(
    getConfig(storeMerchantId, containerId, callback)
  );
};

export const getRegistrationId = (
  vantivIFrame: any,
  uniqueId: string | null
) => {
  vantivIFrame.getPaypageRegistrationId({
    id: config.merchantId,
    orderId: uniqueId ? uniqueId.substring(1, 20) : 'null'
  });
};
