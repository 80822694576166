import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Router } from 'react-router';
import { History } from 'history';

import { theme } from '../../config/style-config';
import { ErrorBoundary } from './ErrorBoundary';
import { InContainerAuthProvider } from '../../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../../context/auth/StandaloneAuthProvider';
import { AuthGuard } from './AuthGuard';

interface Props {
  history: History;
}

export const appTestId = 'appTestId';

export const App = ({ history }: Props) => {
  const { isRenderedByContainer } = window;

  return (
    <div data-testid={appTestId}>
      <ErrorBoundary>
        <MuiThemeProvider theme={theme}>
          <Router history={history}>
            {isRenderedByContainer ? (
              <InContainerAuthProvider>
                <AuthGuard />
              </InContainerAuthProvider>
            ) : (
              <StandaloneAuthProvider>
                <AuthGuard />
              </StandaloneAuthProvider>
            )}
          </Router>
        </MuiThemeProvider>
      </ErrorBoundary>
    </div>
  );
};
