import { SelectFieldOption } from '../../../common/form/select-field/SelectField';

export const selectOptions: SelectFieldOption[] = [
  { label: 'USA', value: 'USA' },
  { label: 'Mexico', value: 'MEX' },
  { label: 'Canada', value: 'CAN' }
];

export const mapPaymentLogCountryToSelectOption = (
  country: string
): SelectFieldOption | undefined => {
  switch (country) {
    case 'USA':
      return { label: 'USA', value: 'USA' };
    case 'MEX':
    case 'Mexico':
      return { label: 'Mexico', value: 'MEX' };
    case 'CAN':
    case 'Canada':
      return { label: 'Canada', value: 'CAN' };
    default:
      return undefined;
  }
};
