import React, { CSSProperties, ReactElement } from 'react';
import Select, { components, StylesConfig } from 'react-select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import isObject from 'lodash/isObject';

import { Colors } from '../../../../config/style-config';

import styles from './SelectField.module.scss';

export const selectFieldTestId = 'selectFieldComponent';

export interface SelectFieldOption {
  label: string;
  value: string;
}

export interface SelectProps {
  name: string;
  value?: SelectFieldOption;
  placeholder?: string;
  customClassName?: string;
  disabled?: boolean;
  options?: SelectFieldOption[];
  onChange?: (selected: any) => void;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  isLowVisible?: boolean;
  hasError?: boolean;
  children?: ReactElement | string;
  hideSelectedOptions?: boolean;
}

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <ArrowDropUpIcon />
        ) : (
          <ArrowDropDownIcon />
        )}
      </components.DropdownIndicator>
    )
  );
};

const customStyles: StylesConfig = {
  container: (provided: CSSProperties, { isDisabled, selectProps }) => {
    return {
      ...provided,
      backgroundColor: isDisabled ? Colors.SWITCH_DISABLED_GREY : Colors.WHITE,
      maxHeight: '40px',
      border: `1px solid ${
        isDisabled
          ? Colors.SWITCH_DARK_GREY
          : selectProps.hasError
          ? Colors.ERROR_MAIN_COLOR
          : Colors.PRIMARY_MAIN_COLOR
      }`
    };
  },
  singleValue: (provided: CSSProperties) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 600,
    color: Colors.BLACK_MEDIUM
  }),
  option: (provided: CSSProperties, { isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? 'rgba(15, 153, 214, 0.2);' : Colors.WHITE,
    color: Colors.BLACK,
    fontSize: '14px',
    padding: '10px',
    position: 'relative',
    '&:hover': {
      backgroundColor: isSelected
        ? 'rgba(15, 153, 214, 0.2)'
        : 'rgba(15, 153, 214, 0.1)',
      '&:before': {
        content: 'none'
      }
    },
    '&:before': {
      content: isSelected ? 'none' : "''",
      position: 'absolute',
      top: 0,
      right: 0,
      height: '1px',
      width: 'calc(100% - 10px)',
      backgroundColor: Colors.CARD_BORDER
    },
    '&:first-of-type': {
      '&:before': {
        content: 'none'
      }
    }
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  placeholder: (provided: CSSProperties) => ({
    ...provided,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: Colors.BLACK,
    fontSize: '14px'
  }),
  dropdownIndicator: (
    provided: CSSProperties,
    { isDisabled, selectProps }
  ) => ({
    ...provided,
    color: isDisabled ? Colors.BLACK : Colors.PRIMARY_MAIN_COLOR,
    opacity: selectProps.options.length === 1 ? 0 : 1,
    '&:hover': {
      color: Colors.PRIMARY_MAIN_COLOR
    }
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    margin: '0',
    borderRadius: 0,
    border: `1px solid ${Colors.PRIMARY_MAIN_COLOR}`,
    boxShadow: 'none',
    backgroundColor: Colors.WHITE,
    width: 'calc(100% + 2px)',
    left: '-1px'
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    padding: 0
  })
};

export const SelectField = (props: SelectProps) => {
  const {
    name,
    placeholder,
    customClassName,
    disabled,
    options,
    children,
    value,
    onChange,
    isLowVisible,
    hasError,
    hideSelectedOptions
  } = props;

  const defaultValue = value
    ? isObject(value)
      ? value
      : { label: value, value: value }
    : undefined;

  return (
    <div
      data-testid={selectFieldTestId}
      className={clsx(styles.selectControl, isLowVisible && styles.opacity02)}
    >
      {children && <span className={styles.selectLabel}>{children}</span>}
      {
        <Select
          components={{ DropdownIndicator, IndicatorSeparator: () => null }}
          className={clsx([
            'selectItem',
            styles.inputControlItem,
            customClassName && styles[customClassName],
            disabled && styles.inputControlDisabled
          ])}
          styles={customStyles}
          isSearchable={false}
          classNamePrefix="select"
          IndicatorSeparator={false}
          isDisabled={disabled}
          placeholder={placeholder}
          options={options}
          menuShouldScrollIntoView={true}
          name={name}
          defaultValue={defaultValue}
          onChange={onChange}
          hasError={hasError}
          hideSelectedOptions={hideSelectedOptions}
          noOptionsMessage={() => null}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: Colors.PRIMARY_MAIN_COLOR
            }
          })}
        />
      }
    </div>
  );
};
