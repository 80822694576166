import React from 'react';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import styles from './PaymentLogSubmitProgress.module.scss';

export const paymentLogSubmitProgressTestId =
  'PaymentLogSubmitProgressComponent';

export const PaymentLogSubmitProgress = () => (
  <div data-testid={paymentLogSubmitProgressTestId} className={styles.progress}>
    <p className={clsx([styles.message, 'm-none'])}>
      Validating Payment Reference ID
    </p>
    <CircularProgress />
  </div>
);
