import React, { PropsWithChildren } from 'react';

import { PromptProps } from './LooseWorkPrompt';
import { useBeforeUnload } from './before-unload-window.hook';

export const BrowserPrompt = (props: PropsWithChildren<PromptProps>) => {
  useBeforeUnload(props.when);

  return React.isValidElement(props.children) ? (
    React.cloneElement(props.children, props)
  ) : (
    <></>
  );
};
