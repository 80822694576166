import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import { Result } from '../../../utils/Result';
import { PaymentLogSubmitForm } from './submit-form/PaymentLogSubmitForm';
import { PaymentLog } from '../../../domain/PaymentLog/PaymentLog';
import { APIErr, APIError } from '../../../domain/APIError';
import { processPaymentLogResult } from './processPaymentLogResult';
import { ProcessPaymentLogRequest } from '../../../domain/PaymentLog/ProcessPaymentLogRequest';
import { ProcessPaymentLogResponse } from '../../../domain/PaymentLog/ProcessPaymentLogResponse';
import { Modal } from '../../common/Modal';
import { PaymentLogSubmitProgress } from './submit-progress/PaymentLogSubmitProgress';
import { PaymentRoute } from '../../app/routes/PaymentRoute';

export const paymentLogSubmitterTestId = 'PaymentLogSubmitterComponent';

interface Props {
  paymentLog: PaymentLog;
}

export const PaymentLogSubmitter = ({ paymentLog }: Props) => {
  const [processPaymentLogResponse, setProcessPaymentLogResponse] = useState<
    ProcessPaymentLogResponse | undefined
  >(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<APIError | undefined>();

  const handleSubmit = async (
    processPaymentLogRequest: ProcessPaymentLogRequest
  ) => {
    setIsSubmitting(true);

    const result: Result<
      ProcessPaymentLogResponse,
      APIError
    > = await processPaymentLogResult(processPaymentLogRequest);

    setIsSubmitting(false);

    if (result.isOk) {
      setProcessPaymentLogResponse(result.value);
    } else {
      setApiError(result.error);
    }
  };

  return (
    <div data-testid={paymentLogSubmitterTestId}>
      <Modal isOpen={isSubmitting}>
        <PaymentLogSubmitProgress />
      </Modal>
      {processPaymentLogResponse && (
        <Alert severity="success">
          <span>
            The Payment was {processPaymentLogResponse.approvalResponse}
          </span>
          <span>
            <Link to={PaymentRoute.Index}>Click here</Link> to use another
            Reference ID
          </span>
        </Alert>
      )}
      {apiError && (
        <Alert severity="error">
          {apiError.errors.map((e: APIErr) => (
            <span key={e.code}>{e.message}</span>
          ))}
          <span>
            <Link to={PaymentRoute.Index}>Click here</Link> to use another
            Reference ID
          </span>
        </Alert>
      )}
      {!processPaymentLogResponse &&
        !apiError &&
        paymentLog.storeMerchantId && (
          <PaymentLogSubmitForm
            paymentLog={paymentLog}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            onSubmit={handleSubmit}
          />
        )}
    </div>
  );
};
