import React, { PropsWithChildren } from 'react';
import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Colors } from '../../config/style-config';

export const buttonTestId = 'ButtonComponent';

interface OwnProps {
  square?: string;
  className?: string;
  type?: string;
  isDashed?: boolean;
}

type Props = OwnProps & ButtonProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 0,
      borderRadius: 0,
      '&:focus': {
        outline: 'none'
      }
    },
    outlined: {
      padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(15)}`,
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.paper,
      fontWeight: 700,
      '&$disabled': {
        backgroundColor: 'transparent',
        border: `2px solid ${Colors.SWITCH_LIGHT_GREY}`
      }
    },
    outlinedPrimary: {
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`
      }
    },
    outlinedSizeLarge: {
      fontSize: theme.typography.pxToRem(14),
      minWidth: theme.typography.pxToRem(120)
    },
    contained: {
      boxShadow: 'none',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      },
      '&$disabled': {
        color: theme.palette.background.paper,
        backgroundColor: Colors.SWITCH_LIGHT_GREY
      }
    },
    containedSizeLarge: {
      fontSize: theme.typography.pxToRem(14),
      minWidth: theme.typography.pxToRem(120)
    },
    disabled: {},
    square50: {
      width: theme.typography.pxToRem(50),
      height: theme.typography.pxToRem(50)
    },
    square40: {
      width: theme.typography.pxToRem(40),
      height: theme.typography.pxToRem(40)
    },
    square30: {
      padding: theme.typography.pxToRem(3),
      width: theme.typography.pxToRem(30),
      height: theme.typography.pxToRem(30)
    },
    dashed: {
      borderStyle: 'dashed',
      '&:hover': {
        borderStyle: 'dashed'
      }
    }
  })
);

export const Button = (props: PropsWithChildren<Props>) => {
  const {
    children,
    square,
    className = '',
    type = 'button',
    isDashed,
    ...rest
  } = props;

  const classes = useStyles();

  const classNames = clsx({
    [classes.square50]: square === '50',
    [classes.square40]: square === '40',
    [classes.square30]: square === '30',
    [classes.dashed]: isDashed,
    [className]: true
  });

  return (
    <MuiButton
      data-testid={buttonTestId}
      className={classNames}
      type={type}
      classes={{
        root: classes.root,
        outlined: classes.outlined,
        outlinedPrimary: classes.outlinedPrimary,
        outlinedSizeLarge: classes.outlinedSizeLarge,
        contained: classes.contained,
        containedSizeLarge: classes.containedSizeLarge,
        disabled: classes.disabled
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};
