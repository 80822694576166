import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { LooseWorkPrompt } from '../../../common/loose-wrok-prompt/LooseWorkPrompt';
import {
  getRegistrationId,
  loadVantivIFrame
} from './PaymentLogsubmitFormUtils';
import { PaymentLog } from '../../../../domain/PaymentLog/PaymentLog';
import { APIError } from '../../../../domain/APIError';
import { ProcessPaymentLogRequest } from '../../../../domain/PaymentLog/ProcessPaymentLogRequest';
import { CreditCard } from '../../../../domain/PaymentLog/CreditCard';
import { InputField } from '../../../common/form/input-field/InputField';
import { Button } from '../../../common/Button';
import {
  SelectField,
  SelectFieldOption
} from '../../../common/form/select-field/SelectField';
import {
  mapPaymentLogCountryToSelectOption,
  selectOptions
} from './mapPaymentLogCountryToSelectOption';
import { VantivResponse } from './VantivResponse';

import styles from './PaymentLogSubmitForm.module.scss';

interface FormValues {
  readonly paymentLogId: string;
  readonly addressLine1?: string;
  readonly addressLine2?: string;
  readonly country?: string;
  readonly city?: string;
  readonly state?: string;
  readonly zip?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly enrollInAutopay?: string;
  readonly savePaymentMethod?: boolean;
}

interface Props {
  paymentLog: PaymentLog;
  isSubmitting: boolean;
  setIsSubmitting: (values: boolean) => void;
  apiError?: APIError;
  setApiError?: (values: APIError | undefined) => void;
  vantivError?: string;
  setVantivError?: (value: string) => void;
  onSubmit: (request: ProcessPaymentLogRequest) => void;
}

export const paymentLogSubmitFormTestId = 'paymentLogSubmitFormComponent';

export const PaymentLogSubmitForm = (props: Props) => {
  const { paymentLog, isSubmitting, setIsSubmitting, onSubmit } = props;

  const [vantivError, setVantivError] = useState('');
  const [vantivIframe, setVantivIframe] = useState();

  const { control, register, handleSubmit, getValues, formState } = useForm<
    FormValues
  >({
    mode: 'onChange',
    defaultValues: {
      ...paymentLog,
      paymentLogId: paymentLog.paymentLogId.toString()
    }
  });

  const handleVantivErrorResponse = (responseCode: string | number) => {
    const isCreditCardNumberError = ['871', '872', '873', '874', '876'].some(
      errorCode => errorCode === responseCode
    );

    const isCVVError = ['881', '882', 883].some(
      errorCode => errorCode === responseCode
    );

    if (isCreditCardNumberError) {
      setVantivError(
        'Please check and re-enter your credit card number and try again.'
      );
    } else if (isCVVError) {
      setVantivError(
        'Please check and re-enter your card validation number and try again.'
      );
    } else if (responseCode === '884') {
      setVantivError('884');
    } else {
      setVantivError(
        'We are experiencing technical difficulties. Please try again'
      );
    }
  };

  const handleVantivCallback = (response: VantivResponse) => {
    if (response.response === '870') {
      setIsSubmitting(true);

      const card = new CreditCard(response);

      const formValues: FormValues = getValues();

      const request: ProcessPaymentLogRequest = {
        paymentLogId: paymentLog.paymentLogId.toString(),
        paymentToken: response.paypageRegistrationId,
        cardType: card.type,
        expirationDate: card.cardExpiration,
        lastFour: card.lastDigits,
        ...(paymentLog.cardType === card.type ? {} : formValues),
        savePaymentMethod: formValues.savePaymentMethod ? 'Y' : 'N'
      };

      onSubmit(request);
    } else {
      handleVantivErrorResponse(response.response);
    }
  };

  useEffect(() => {
    setVantivIframe(
      loadVantivIFrame(
        paymentLog.storeMerchantId,
        'ccframe',
        handleVantivCallback
      )
    );
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = () => {
    getRegistrationId(vantivIframe, paymentLog.storeMerchantId);
  };

  const { dirty } = formState;

  return (
    <>
      <LooseWorkPrompt when={dirty} />
      <form
        data-testid={paymentLogSubmitFormTestId}
        className={clsx(['form'])}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Box pb="1.5rem">
              <Typography variant="h5">Customer Information</Typography>
            </Box>

            <Box pb="1.5rem">
              <InputField
                name="addressLine1"
                label="Address 1"
                refContent={register()}
                placeholder="Address 1"
                autoComplete="off"
              />
            </Box>

            <Box pb="1.5rem">
              <InputField
                name="addressLine2"
                label="Address 2"
                refContent={register()}
                placeholder="Address 2"
                autoComplete="off"
              />
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box pb="1.5rem">
                  <InputField
                    name="city"
                    label="City"
                    refContent={register()}
                    placeholder="City"
                    autoComplete="off"
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box pb="1.5rem">
                  <InputField
                    name="state"
                    label="State"
                    refContent={register()}
                    placeholder="State"
                    autoComplete="off"
                  />
                </Box>
              </Grid>
            </Grid>

            <Box pb="1.5rem">
              <InputField
                name="zip"
                label="Zip"
                refContent={register()}
                placeholder="Zip"
                autoComplete="off"
              />
            </Box>

            <Controller
              as={SelectField}
              name="country"
              options={selectOptions}
              control={control}
              defaultValue={mapPaymentLogCountryToSelectOption(
                paymentLog.country
              )}
              onChange={([value]: SelectFieldOption[]) => value.value}
            >
              Country
            </Controller>
          </Grid>

          <Grid item md={6} xs={12}>
            <Box pb="1.5rem">
              <Typography variant="h5">Payment Information</Typography>
            </Box>

            <Box pb="1.5rem">
              <InputField
                name="firstName"
                label="First Name"
                refContent={register()}
                placeholder="First Name"
                autoComplete="off"
              />
            </Box>

            <Box pb="1.5rem">
              <InputField
                name="lastName"
                label="Last Name"
                refContent={register()}
                placeholder="Last Name"
                maxLength={36}
                autoComplete="off"
              />
            </Box>

            <div id="ccframe" />

            <FormControlLabel
              className={styles.savePaymentMethod}
              control={
                <Controller
                  as={Checkbox}
                  color="primary"
                  name="savePaymentMethod"
                  control={control}
                  defaultValue={false}
                />
              }
              label="Save Payment Method"
            />

            <Box>
              <Button
                color="primary"
                size="large"
                variant="contained"
                type="submit"
                className={styles.submitBtn}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>

        {vantivError && (
          <Box mt="1.5rem">
            <Alert severity="error">
              <span>{vantivError}</span>
            </Alert>
          </Box>
        )}
      </form>
    </>
  );
};
