import React from 'react';

import { BrowserPrompt } from './BrowserPrompt';
import { RouterPrompt } from './RouterPrompt';

export interface PromptProps {
  when: boolean;
  message?: string;
}

export const LooseWorkPrompt = (props: PromptProps) => (
  <BrowserPrompt {...props}>
    <RouterPrompt {...props} />
  </BrowserPrompt>
);
